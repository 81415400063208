import styled from '@emotion/styled';
import { breakpoints, colors, dimensions } from '../../styles/variables';
import Dropdown from '../../assets/icon-arrow.inline.svg';
import topBackground from '../../assets/backgrounds/navy-blue-top.svg';
//import bottomBackground from '../../assets/backgrounds/navy-blue-bottom.svg';
import bottomBackground from '../../../static/salespage/7th section/bottom-beige-path-coverup.svg';
import optionBanner from '../../../static/salespage/7th section/option-1.svg';
import { Visibility } from '../../types';

const TOP_BACKGROUND_HEIGHT = '1.25vw';
const BOTTOM_BACKGROUND_HEIGHT = '1.25vw';
 
export const SafariWrapper = styled.div`
  max-width: 100%;
  margin-bottom: -${BOTTOM_BACKGROUND_HEIGHT};
  padding-bottom: ${BOTTOM_BACKGROUND_HEIGHT};
  overflow: hidden;

`;

export const Wrapper = styled.section<Visibility>`
  
  margin-top: 96px;
  padding: 78px ${dimensions.containerPadding}px;
  position: relative;
  z-index: 2;
  background-color: ${colors.navyBlue};
  color: ${colors.white};

  @media screen and (min-width: 2300px) {
    padding-bottom:120px;
  }
  
  /*opacity: 0;
  transform: translateY(10%);
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
  ${({ isVisible }) =>
    isVisible &&
    `
    opacity: 1;
    transform: translateY(0);
  `}*/

  &:before,
  &:after {
    content: '';
    width: 100%;
    position: absolute;
    left: 0;
    background-size: contain;
  }
  &:before {
    height: ${TOP_BACKGROUND_HEIGHT};
    top: calc(-${TOP_BACKGROUND_HEIGHT} + 2px);
    background: url(${topBackground}) no-repeat center 0;

    @media screen and (max-width: 1024px) {
      margin-top: 1px;
      transform: scale(1.04);
    }
    @media screen and (max-width: ${breakpoints.lg}px) {
      transform: scale(1.02);
    }
  }
  &:after {
    height: 50px;
    bottom: -20px;
    background: url(${bottomBackground}) no-repeat center 0;
    background-size: 100%;
    @media screen and (max-width: ${breakpoints.lg}px) {
      transform: scale(1.02);
    }
  }

  @media screen and (max-width: ${breakpoints.lg}px) {
    margin-top: 130px;
    padding-top: 40px;
    padding-bottom: 40px;
  }

  @media screen and (max-width: ${breakpoints.md}px) {
    margin-top: 80px;
    margin-bottom:37px;
    padding-bottom:180px;
  }
  @media screen and (max-width: ${breakpoints.sm}px) {
    margin-bottom:32px;
  }
`;

export const OptionBanner = styled.figure`
  width: 100%;
  text-align: center;
  margin :auto;
  margin-top: -150px;
  z-index: 10;
  position: absolute;
  @media screen and (max-width: ${breakpoints.lg}px) {
    margin-top: -120px;
  }
  @media screen and (max-width: ${breakpoints.sm}px) {
    margin-top: -100px;
    width:90%;
  }
  
  img{
    height: 80px;
    @media screen and (max-width: ${breakpoints.md}px) {
      width:80%;
    }
  }

  &:before {
    display: block;
    content: '';
    height: 300px;
    width: 350px;
    background: url('/salespage/7th section/stopwatch.svg') no-repeat left top, url('/salespage/7th section/easy.svg') no-repeat right;
    background-size: 180px 180px;
    margin: auto;
    margin-top: -20px;
    margin-left:25%;
    z-index: 3;
    position: absolute;
    @media screen and (max-width: ${breakpoints.xl}px) {
      margin-left:16%;
      background-size: 150px 150px;
      height: 280px;
      width: 290px;
    }
    @media screen and (max-width: ${breakpoints.lg}px) {
      transform: scale(1.02);
      margin-left:10%;
      background-size: 120px 120px;
      height:220px;
      width: 250px;
    }
    @media screen and (max-width: ${breakpoints.md}px) {
      background-size: 50%; margin-left:12%;
      width:200px;height:200px;
    }
    @media screen and (max-width: ${breakpoints.sm}px) {
      margin-left:-20px;
      height: 230px;
      width: 200px;
    }
    @media screen and (max-width: 359px) {
      margin-left:-10%;
    }
}

  &:after {
    display: block;
    content: '';
    height: 600px;
    width: 200px;
    background: url('/salespage/7th section/long-red-arrow-down.svg') no-repeat center 0;
    background-size: contain;
    margin: auto;
    margin-top: -60px;
    margin-left:66%;
    z-index: 3;
    position: absolute;
    @media screen and (max-width: ${breakpoints.xl}px) {
      margin-left:68%;
      height: 200px;
    width:170px;
    }
    @media screen and (max-width: ${breakpoints.lg}px) {
      transform: scale(1.02);width:180px;height:250px;
      right: 90px;
    top: 30px;margin:0;
    }
    @media screen and (max-width: 950px) {
      right: 50px;

    }
    @media screen and (max-width: 889px) {
      right: 20px;
      height: 230px;
      width:150px;

    }
    @media screen and (max-width: ${breakpoints.md}px) {
      position:absolute;margin:0;top:20px;right:20px;height: 300px;background-size: 50%;
    }
    @media screen and (max-width: ${breakpoints.sm}px) {
      background-size: 50%;right:-50px;top:30px;
    }
    @media screen and (max-width: 359px) {
      background-size: 35%;right:-45px;
    }
    
  }

`;

export const Heading = styled.header`
  text-align: center;margin-top:60px;
  @media screen and (max-width: ${breakpoints.md}px) {
    margin-top:40px;
  }
`;

export const Title = styled.h2`
margin: 0px 0 0 0;
  color: currentColor;
  font-size: 38px;
  font-weight: 900;
  line-height: ${dimensions.lineHeight.heading};

  @media screen and (max-width: ${breakpoints.xl}px) {
    margin: 30px 0 0 0;
     }
  @media screen and (max-width: ${breakpoints.lg}px) {
    font-size: 38px;
     }
  @media screen and (max-width: ${breakpoints.md}px) {
    margin: 10px 0 0 0;
  }
  @media screen and (max-width: ${breakpoints.sm}px) {
    font-size: 32px;margin-top:60px;
  }
  @media screen and (max-width: 359px) {
    margin-top:30px;
  }
  
`;

export const SubTitle = styled.h3`
  margin: 0;
  color: currentColor;
  font-size: ${dimensions.fontSize.medium}px;
  font-weight: 100;

  line-height: ${dimensions.lineHeight.heading};

  @media screen and (max-width: ${breakpoints.lg}px) {
    //font-size: ${dimensions.fontSize.medium * 0.7}px;
    font-size:18px;
  }
`;

export const MiniSubTitle = styled.h5`
  margin: auto;
  color: currentColor;
 
    font-size: ${dimensions.fontSize.medium}px;
 

  font-weight: 100;
  line-height: ${dimensions.lineHeight.heading};

  margin-top: 50px;

  @media screen and (max-width: ${breakpoints.lg}px) {
    // font-size: ${dimensions.fontSize.heading * 0.7}px;
    font-size:18px;
    margin-bottom:50px;

  }
`;

export const Section = styled.section`
  display: grid;
  max-width: ${1080 + dimensions.containerPadding}px;
  width: 100%;
  margin: 34px auto 0 auto;
  position: relative;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 27px;

  @media screen and (max-width: ${breakpoints.lg}px) {
    margin-top: 0;
    grid-template-columns: 1fr;
    width:600px;margin:0 auto;
  }
  @media screen and (max-width: ${breakpoints.md}px) {
    width:550px; 
  }
  @media screen and (max-width: 576px) {
    width:100%;
  }

`;

export const LeftColumn = styled.div`
  width: 350px;
  margin-left: 50px;
  @media screen and (max-width: ${breakpoints.md}px) {
  width:90%;
  margin-left:0;

  br{display:none;}
  }
`;

export const RightColumn = styled.div`
  margin-left: 0px;
`;

export const Whiteboard = styled.div`
  transform: translate(21px, -12px);
  width:550px;
  margin-top: 0px;
  border-radius: 5px;
  //background-color: #fff;
  //height: 400px;
  position:relative;
    img{width:100%;}

 @media screen and (max-width: ${breakpoints.xl}px) {
    width:420px;
  }
  @media screen and (max-width: ${breakpoints.lg}px) {
    max-width: 90%;
    transform: none;
    margin-bottom:30px;
    width:100%;
  }


  &:after {
    display: block;
    content: '';
    height: 300px;
    width: 100px;
    background: url('/salespage/7th section/astronaut.svg') no-repeat center 0;
    background-size: contain;
    margin: auto;
    top: 80px;
    right:-60px;
    z-index: 3;
    position: absolute;
    @media screen and (max-width: ${breakpoints.lg}px) {
      transform: scale(1.02);
    }
    @media screen and (max-width: ${breakpoints.md}px) {
      background-size: 95%;
      height: 200px;
    width: 80px;right:-40px;
      transform:none;
    }
    @media screen and (max-width: ${breakpoints.sm}px) {
      background-size: 90%;
      height: 140px;
     width: 70px;  top:40px;
    }
    @media screen and (max-width: 400px) {
      top:20px;
    }
  }
`;

export const List = styled.div`
  margin: 0;
  padding: 0;
  list-style: none;
  
 
    font-size: ${dimensions.fontSize.medium}px;
 
  width: 100%;
  height: 80px;
  margin-bottom: 20px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
 
  @media screen and (max-width: ${breakpoints.lg}px) {
    position:relative;padding-left:0px;
    height:auto;padding-bottom:25px;
    margin-bottom: 30px; 
    width:95%;
  }
  opacity:0.6;

  &.second::after{
    margin-top: -15px;
  }
  &.third::after{
    margin-top: -55px;
  }
  &.fourth::after{
    margin-top: -15px;
  }

  &.active {
    opacity:1;
    &::after{
      width: 10px;
      height:10px;
      content: '';
      float: right;
      top:8px;
      border: solid rgba(255, 255, 255, 0.1);;
      border-width: 0 3px 3px 0;
      display: inline-block;
      padding: 3px;
      transform: rotate(-45deg);
      -webkit-transform: rotate(-45deg);
      color: #fff;
    }
  }


 
  // &:first-child::after{
  //   width: 10px;
  //   height:10px;
  //   content: '';
  //   float: right;
  //   border: solid rgba(255, 255, 255, 0.1);;
  //   border-width: 0 3px 3px 0;
  //   display: inline-block;
  //   padding: 3px;
  //   transform: rotate(-45deg);
  //   -webkit-transform: rotate(-45deg);
  //   color: #fff;
  // }

  div.text{
    margin-left: 30px;
    @media screen and (max-width: ${breakpoints.lg}px) {
      margin-left: 0px;
    }
  }
`;

export const Number = styled.div`
  border-radius: 20px;
  font-size: 10px;
  width: 20px;
  height: 20px;
  float: left;
  margin: auto;
  margin-right: 10px;
  text-align: center;
  line-height: 20px;
  @media screen and (max-width: ${breakpoints.md}px) {
    br{
      display:none;
    }
  }
`;

export const ListMob = styled.div`
margin: 0;
padding: 0;
list-style: none;
width: 100%;
height: 80px;
margin-bottom: 20px;
border-bottom: 1px solid rgba(255, 255, 255, 0.1);

@media screen and (max-width: ${breakpoints.lg}px) {
  position:relative;padding-left:0px;
  height:auto;padding-bottom:25px;
  margin-bottom: 30px; 
  width:95%;
}

@media screen and (max-width: ${breakpoints.md}px) {
 
    font-size: ${dimensions.fontSize.medium * 0.9}px;
 
}

@media screen and (max-width: ${breakpoints.sm}px) {
 
    font-size: ${dimensions.fontSize.medium * 0.8}px;
 

}



  div.text{
    margin-left: 30px;
    @media screen and (max-width: ${breakpoints.lg}px) {
      margin-left: 0px;
    }
  }
`;
export const NumberMob = styled.div`
  font-size: 10px;
  width: 20px;
  height: 80px;
  float: left;
  margin: auto;
  margin-right: 10px;
  text-align: center;
  line-height: 20px;
`;
export const Cont = styled.div`


`;

export const Steps = styled.div`
  list-style: none;
 
  margin: auto;
  margin-top: 30px;
  text-align:left;
  font-size: 14px;

 
    width: 720px;
    font-size: ${dimensions.fontSize.medium}px;
 

  
  @media screen and (max-width: ${breakpoints.md}px) {
    width:100%;
    padding-left:30px;position:relative;
 
      font-size: ${dimensions.fontSize.medium * 0.9}px;
 
  }

  @media screen and (max-width: ${breakpoints.sm}px) {
 
      font-size: ${dimensions.fontSize.medium * 0.8}px;
 
  }

  &:after{
    display: block;
    content: '';
    height: 300px;
    width: 300px;
    background: url('/salespage/7th section/app-icons.svg') no-repeat center 0;
    background-size: contain;
    float: right;
    margin-right: -280px;
    margin-top: -180px;
    @media screen and (max-width: ${breakpoints.xl}px) {
      margin-right: -220px;
    }
    @media screen and (max-width: ${breakpoints.lg}px) {
      height: 200px;
      width: 200px;
      margin-right: -100px;
      background-size:100%;
      margin-top: -130px;
    }
    @media screen and (max-width: ${breakpoints.md}px) {
      margin-right: 0px;
      margin-top:0;
    }
  }

  div{
    margin-bottom: 30px;

    &:before{
      display: block;
      content: '';
      height: 20px;
      width: 20px;
      background: url('/salespage/7th section/white-arrow-right.svg') no-repeat center 0;
      background-size: contain;
      float: left;
      margin-right: 20px;
      @media screen and (max-width: ${breakpoints.md}px) {
        margin-right: 0px;
        position:absolute;left:0;
      }
    }


  }
`;